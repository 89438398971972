:root {
  --color-text: white;
  --color-primary: black;
  --color-contrast: #0084ff;
  --color-secondary: #004280;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: black !important;
  /* background-size: 40px 40px;
  background-image: linear-gradient(to right, rgba(247, 192, 140, 0.2) 2px, transparent 1px),
      linear-gradient(to bottom, rgba(247, 192, 140, 0.2) 2px, transparent 1px); */
}

body * {
  font-family: Mitr, Courier !important;
  color: var(--color-text);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap");

/* .rtf.open .rtf--ab__c:nth-child(1) {
  transform: translateX(60px) scale(1) !important;
}

.rtf.open .rtf--ab__c > span {
  transform: translateY(-50%) !important;
  background-color: yellow !important;
} */

.rtf--mb__c ul {
  transform: translateY(-50px) !important;
}
.rtf.open .rtf--ab__c > span {
  font-size: large;
}

.pageContainer {
  display: flex;
  flex: 1;
  background-color: var(--color-primary);
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fullHeight {
  height: 100vh;
}

.contentContainer {
  background-color: var(--color-primary);
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media only screen and (min-width: 500px) {
  .contentContainer {
    max-width: 500px;
  }
}

.contentContainerCapturing {
  max-width: 600px;
  width: 120%;
  padding: 40px;
  padding-bottom: 0px;
}

.mawContainer {
  width: 100%;
  /* background-color: green !important; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
}
@media only screen and (min-width: 500px) {
  .mawContainer {
    justify-content: flex-start;
    align-items: flex-end;
    padding-left: 50px;
    padding-top: 30px;
    flex-direction: row;
  }
}

.maw img {
  position: absolute;
  bottom: 0;
}

.maw {
  max-width: 200px;
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  margin-bottom: 50px;
}

@media only screen and (min-width: 500px) {
  .maw {
    margin-bottom: 0;
  }
}

.maw > div {
  display: inline !important;
}

.mawName {
  flex: 1;
}

.textAreaMawName {
  font-size: large;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  flex: 1;
  background-color: transparent;
  resize: none;
  caret-color: var(--color-contrast);
  text-align: center;
  /* display: flex; */
}

@media only screen and (min-width: 500px) {
  .textAreaMawName {
    text-align: left;
    vertical-align: bottom;
    /* line-height: 1em; */
  }
}

.textContainer {
  /* height: 100px; */
  /* padding-bottom: 20px; */
  position: relative;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
}

.textAreaMessage {
  font-size: x-large;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  flex: 1;
  background-color: transparent;
  margin-bottom: 4px;

  /* border-top: 3px dashed var(--color-text);
  border-bottom: 3px dashed var(--color-text);
  border-radius: 1; */

  resize: none;
  caret-color: var(--color-contrast);
}

.toolbar {
  display: flex;
  /* flex-direction: row; */
  width: 100%;
  /* background-color: var(--color-secondary) !important; */
  position: relative;
  padding-top: 40px;
}

@media only screen and (min-width: 500px) {
  .toolbar {
    max-width: 500px;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 9999;
  opacity: 0.5;
}

.creditLine {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 5px;
  opacity: 0.6;
  font-size: small;
}

/* a[href^="http://"]
{
  background: url(/images/external.png) center right no-repeat;
  padding-right: 13px;
} */

.card,
.card * {
  color: black;
}

.card {
  background-color: white;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
}

.card a {
  text-decoration: underline;
  color: var(--color-contrast);
}

@media only screen and (min-width: 500px) {
  .card {
    max-width: 500px;
    margin-top: 20px;
    border-radius: 10px;
  }
}
